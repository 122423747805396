import React, { useState } from "react";
import SEO from "../components/SEO";
import { navigate } from "gatsby";
import api from "../services/api";
import { toast, ToastContainer } from "react-toastify";
import { ClipLoader } from "react-spinners";
import BackSlider from "../components/Login/BackSlider";
import CardRound from "../components/Login/CardRound";

import bg1 from "../../src/images/login/bgs/bg-1.jpg";
import bg2 from "../../src/images/login/bgs/bg-2.jpg";
import Loading from "../components/Login/Loading";

const IndexPage = props => {
    const [step, setStep] = useState(1);
    const [username, setUsername] = useState(props.location?.state?.email);
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [passwordValidation, setPasswordValidation] = useState(false);
    const [loading, setLoading] = useState(false);

    const renderButtonText = () => {
        switch (step) {
            case 1:
                return "Enviar";
            case 2:
                return "Salvar";
            case 3:
                return "Fazer login";
            default:
                return "Enviar";
        }
    };
    const bgs = [
        {
            urlBg: bg1,
            text: "Vendemmia Logística Integrada",
            timeSecs: 5,
            topicId: 1, // 1- Navio, 2 - Caminhão, 3 - Armazém, null - Ocultar
            filter: 0, // 0 - Desativado, qualquer outro valor é o brilho do filtro. (Atualmente desativado)
        },
        {
            urlBg: bg2,
            text: "Pessoas e Negócios conectados",
            timeSecs: 5,
            topicId: 2,
            filter: 0,
        },
    ];
    return (
        <>
            <SEO title="Recuperar Senha" />
            <BackSlider slides={bgs}>
                <CardRound hideMobile={true}>
                    <div className="mt-4">
                        {step === 1 && (
                            <p>Informe o e-mail cadastrado para receber instruções para a recuperação da sua conta</p>
                        )}
                        {step === 2 && (
                            <p>Se o seu e-mail estiver cadastrado, você irá receber um código para alterar sua senha</p>
                        )}
                        <div className="flex flex-col w-full">
                            {step === 1 && (
                                <div className="field">
                                    <label htmlFor="email">E-mail</label>
                                    <input
                                        id="email"
                                        type="text"
                                        value={username}
                                        onChange={e => setUsername(e.target.value.trim())}
                                    />
                                </div>
                            )}
                            {step === 2 && (
                                <>
                                    <div className="large field">
                                        <label htmlFor="code">Insira o código recebido por email</label>
                                        <input
                                            id="code"
                                            type="text"
                                            value={code}
                                            autoComplete="off"
                                            onChange={e => setCode(e.target.value.trim())}
                                        />
                                    </div>
                                    <div className="large field">
                                        <label htmlFor="newPassword">Insira sua nova senha</label>
                                        <input
                                            id="newPassword"
                                            type="password"
                                            value={password}
                                            onChange={e => setPassword(e.target.value.trim())}
                                        />
                                    </div>
                                    <div className="large field">
                                        <label htmlFor="confirmPassword">Confirme a senha</label>
                                        <input
                                            id="confirmPassword"
                                            className="focus:outline-none border-2 border-#EBEBEB py-1 px-2"
                                            type="password"
                                            onChange={e => setPasswordValidation(e.target.value.trim())}
                                        />
                                    </div>
                                </>
                            )}
                            {step === 3 && (
                                <p className="text-C6 text-sm text-center px-10">Sua senha foi alterada com sucesso!</p>
                            )}
                            <div
                                className={
                                    step === 3
                                        ? "self-center flex justify-between w-full"
                                        : "self-end flex justify-between w-full"
                                }
                            >
                                <button
                                    onClick={() => {
                                        if (step === 1) {
                                            return navigate("/", { state: { email: username } });
                                        } else {
                                            setStep(step - 1);
                                        }
                                    }}
                                    className="remember mt-6"
                                    to="/"
                                >
                                    &lt; voltar
                                </button>
                                <button
                                    onClick={() => {
                                        if (step === 1) {
                                            setLoading(true);

                                            api.post("recover/request", {
                                                username: username,
                                            })
                                                .then(response => {
                                                    toast.success("Código enviado para email informado!");
                                                    setStep(2);
                                                    setLoading(false);
                                                })
                                                .catch(error => {
                                                    setLoading(false);
                                                });
                                        } else if (step === 2) {
                                            if (password !== passwordValidation) {
                                                toast.error("As senhas não são iguais!");
                                            } else {
                                                setLoading(false);
                                                api.post("recover/send", {
                                                    code: code,
                                                    password: password,
                                                })
                                                    .then(response => {
                                                        toast.success("Senha alterada com sucesso!");
                                                        setStep(3);
                                                        setLoading(false);
                                                    })
                                                    .catch(error => {
                                                        setLoading(false);
                                                    });
                                            }
                                        } else if (step === 3) {
                                            navigate("/");
                                        }
                                    }}
                                    className="btn mt-6"
                                >
                                    {renderButtonText()} {loading && <ClipLoader size="13" color="white" />}
                                </button>
                            </div>
                        </div>
                    </div>
                </CardRound>
                <ToastContainer />
                <Loading status={loading} />
            </BackSlider>
        </>
    );
};
export default IndexPage;
